import { Button, Form, Input, InputRef, Row, Space, Typography } from "antd"
import Cookies from "js-cookie"
import { jwtDecode } from "jwt-decode"
import React, { FC, Fragment, useEffect, useRef, useState } from "react"
import { NavLink, useNavigate } from "react-router-dom"
import { login } from "../../../api/auth"
import { SvgIcon } from "../../../handlers/icon"
import { App } from "../../../types"
import "./index.less"

export const getUserFromSessionStorage = () => {
  const userString = sessionStorage.getItem("user")
  return userString ? JSON.parse(userString) : null
}

export const setUserToSessionStorage = (user: App.UserLoginResponse | null) => {
  sessionStorage.setItem("user", JSON.stringify(user))
}

const SignIn: FC = () => {
  const { Title, Text } = Typography
  const [wrongCredentialsError, setWrongCredentialsError] = useState(false)
  const [loading, setLoading] = useState(false)
  const inputTagRef = useRef<InputRef>(null)
  const navigate = useNavigate()

  const validateFields = async (values: { username: string; password: string }) => {
    setLoading(true)
    const user = await login(values)
    if (!user) {
      setWrongCredentialsError(true)
    } else {
      setUserToSessionStorage(user)
      const decodedToken = jwtDecode(user.token)
      const expirationDate = decodedToken.exp ? new Date(decodedToken.exp * 1000) : undefined
      Cookies.set("token", user.token, { expires: expirationDate })
      setWrongCredentialsError(false)
      navigate("/students")
    }
    setLoading(false)
  }

  useEffect(() => {
    if (inputTagRef.current) {
      inputTagRef.current.focus()
    }
  }, [inputTagRef.current])

  return (
    <Fragment>
      <Row align={"middle"} justify={"center"}>
        <SvgIcon fill={"#174578"} type={"logoIcon"} />
      </Row>
      <Space direction={"vertical"} wrap={false} size={16}>
        <Title level={2} className={"form-title"}>
          Sign In
        </Title>
        <Text className={"form-subtitle"}>Please enter your credentials to access your account</Text>
      </Space>
      <Form<{ username: string; password: string }>
        disabled={loading}
        className={"modal-form modal-sign-in"}
        name={"sign-in"}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        autoComplete={"new-password"}
        onFinish={validateFields}
      >
        <Form.Item className={"form-item"} label={"Username"} name={"username"}>
          <Input ref={inputTagRef} required />
        </Form.Item>

        <Form.Item className={"form-item"} label={"Password"} name={"password"}>
          <Input.Password required />
        </Form.Item>
        <Row className={"forgot-password"} justify={"space-between"} align={"middle"}>
          {wrongCredentialsError && (
            <Space direction={"horizontal"} size={9} align={"center"}>
              <SvgIcon type={"crossIcon"} fill={"#B25758"} />
              <Text className={"danger"}>Incorrect Username or Password</Text>
            </Space>
          )}
          <NavLink to={"/request-email-confirm"}>Forgot Password?</NavLink>
        </Row>
        <Form.Item>
          <Button className={"primary login-btn"} htmlType={"submit"}>
            Log In
          </Button>
        </Form.Item>
      </Form>
    </Fragment>
  )
}

export { SignIn }
