import React, { FC } from "react"
import { useNavigate } from "react-router-dom"
import { SvgIcon } from "../../../handlers/icon"

const Logo: FC = () => {
  const navigate = useNavigate()
  return <SvgIcon className={"main-logo"} fill={"white"} type={"logoIcon"} onClick={() => navigate("/")} />
}

export { Logo }
