import { FC, SVGProps } from "react"
import { ReactComponent as AlertErrorIcon } from "./images/alert-error.svg"
import { ReactComponent as AlertFilled } from "./images/alert-filled.svg"
import { ReactComponent as ArrowBreadcrumb } from "./images/arrow-breadcrumbs.svg"
import { ReactComponent as NavigateIcon } from "./images/arrow-right-circled.svg"
import { ReactComponent as LogoIcon } from "./images/avstar-logo.svg"
import { ReactComponent as BookFilled } from "./images/book-filled.svg"
import { ReactComponent as BooksIcon } from "./images/books-icon.svg"
import { ReactComponent as BurgerIcon } from "./images/burger-icon.svg"
import { ReactComponent as CaretPagination } from "./images/caret-pagination.svg"
import { ReactComponent as ChartIcon } from "./images/chart.svg"
import { ReactComponent as CheckCircleFilled } from "./images/check-circle-filled.svg"
import { ReactComponent as CheckSingle } from "./images/check-single.svg"
import { ReactComponent as CrossIcon } from "./images/cross-icon.svg"
import { ReactComponent as CrossSingle } from "./images/cross-single.svg"
import { ReactComponent as DeleteIcon } from "./images/delete-icon.svg"
import { ReactComponent as DuplicateIcon } from "./images/duplicate-icon.svg"
import { ReactComponent as EditIcon } from "./images/edit-icon.svg"
import { ReactComponent as ExamIcon } from "./images/exam-icon.svg"
import { ReactComponent as ExtraIcon } from "./images/extra-icon.svg"
import { ReactComponent as EyeIcon } from "./images/eye-icon.svg"
import { ReactComponent as AddFileIcon } from "./images/file-add-icon.svg"
import { ReactComponent as StatisticFileIcon } from "./images/file-icon.svg"
import { ReactComponent as FileIcon } from "./images/file.svg"
import { ReactComponent as FilterIcon } from "./images/filter-icon.svg"
import { ReactComponent as GroupsIcon } from "./images/groups.svg"
import { ReactComponent as HomeIcon } from "./images/home-icon.svg"
import { ReactComponent as InfoIcon } from "./images/info.svg"
import { ReactComponent as LogoAvstarBurger } from "./images/logo-burger.svg"
import { ReactComponent as CompanyActiveIcon } from "./images/monitor-check.svg"
import { ReactComponent as CompanyInactiveIcon } from "./images/monitor-cross.svg"
import { ReactComponent as BookOpenFilled } from "./images/open-book-filled.svg"
import { ReactComponent as SearchIcon } from "./images/search-icon.svg"
import { ReactComponent as ShelveIcon } from "./images/shelve-filled.svg"
import { ReactComponent as SignOut } from "./images/sign-out.svg"
import { ReactComponent as StopIcon } from "./images/stop-icon.svg"
import { ReactComponent as StudentHatFilled } from "./images/student-hat-filled.svg"
import { ReactComponent as StudentHatIcon } from "./images/student-hat.svg"
import { ReactComponent as SupportIcon } from "./images/support-icon.svg"
import { ReactComponent as WarningFilled } from "./images/warning-filled.svg"

export type IconTypes =
  | "navigateIcon"
  | "supportIcon"
  | "homeIcon"
  | "signOut"
  | "filterIcon"
  | "logoAvstarBurger"
  | "stopIcon"
  | "statisticFileIcon"
  | "bookFilled"
  | "bookOpenFilled"
  | "shelveIcon"
  | "deleteIcon"
  | "editIcon"
  | "arrowBreadcrumb"
  | "eyeIcon"
  | "addFileIcon"
  | "crossIcon"
  | "searchIcon"
  | "caretPagination"
  | "extraIcon"
  | "checkCircleFilled"
  | "warningFilled"
  | "studentHatFilled"
  | "alertFilled"
  | "burgerIcon"
  | "logoIcon"
  | "infoIcon"
  | "chartIcon"
  | "fileIcon"
  | "groupsIcon"
  | "studentHatIcon"
  | "crossSingle"
  | "companyActive"
  | "companyInactive"
  | "checkSingle"
  | "booksIcon"
  | "examIcon"
  | "duplicateIcon"
  | "alertErrorIcon"
  | string

const icons: {
  [key: string]: FC<SVGProps<SVGSVGElement>>
} = {
  navigateIcon: NavigateIcon,
  supportIcon: SupportIcon,
  homeIcon: HomeIcon,
  companyActive: CompanyActiveIcon,
  companyInactive: CompanyInactiveIcon,
  signOut: SignOut,
  filterIcon: FilterIcon,
  logoAvstarBurger: LogoAvstarBurger,
  stopIcon: StopIcon,
  statisticFileIcon: StatisticFileIcon,
  crossSingle: CrossSingle,
  bookFilled: BookFilled,
  bookOpenFilled: BookOpenFilled,
  shelveIcon: ShelveIcon,
  deleteIcon: DeleteIcon,
  editIcon: EditIcon,
  arrowBreadcrumb: ArrowBreadcrumb,
  eyeIcon: EyeIcon,
  addFileIcon: AddFileIcon,
  crossIcon: CrossIcon,
  searchIcon: SearchIcon,
  caretPagination: CaretPagination,
  extraIcon: ExtraIcon,
  alertFilled: AlertFilled,
  checkCircleFilled: CheckCircleFilled,
  warningFilled: WarningFilled,
  studentHatFilled: StudentHatFilled,
  fileIcon: FileIcon,
  groupsIcon: GroupsIcon,
  studentHatIcon: StudentHatIcon,
  chartIcon: ChartIcon,
  infoIcon: InfoIcon,
  logoIcon: LogoIcon,
  burgerIcon: BurgerIcon,
  checkSingle: CheckSingle,
  booksIcon: BooksIcon,
  examIcon: ExamIcon,
  duplicateIcon: DuplicateIcon,
  alertErrorIcon: AlertErrorIcon,
} as const

type SvgIconProps = SVGProps<SVGSVGElement> & { type: IconTypes }

const SvgIcon: FC<SvgIconProps> = ({ type, ...svgProps }) => {
  const Icon = icons[type] ?? null
  return Icon && <Icon {...svgProps} />
}

export { SvgIcon }
