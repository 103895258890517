import "./Navigation.less"

import { Menu, MenuProps } from "antd"
import React, { FC, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"

const Navigation: FC<MenuProps> = ({ theme = "light", ...props }) => {
  const { pathname } = useLocation()
  const [activeKey, setActiveKey] = useState<string>("/students")

  useEffect(() => {
    if (pathname === "/") {
      setActiveKey("/students")
    } else {
      setActiveKey(pathname)
    }
  }, [pathname])

  return <Menu activeKey={activeKey} className={"main-navigation"} theme={theme} {...props} />
}

export default Navigation
