import moment from "moment/moment"
import React, { createContext, useState } from "react"
import { App } from "../types"

interface SecondaryContextProps {
  children: React.ReactNode
}

export const SecondaryContext = createContext<{
  selectedAssignment: App.Assignment | null
  setSelectedAssignment: React.Dispatch<React.SetStateAction<App.Assignment | null>>
  companiesData: App.CompanyInformation[]
  setCompaniesData: React.Dispatch<React.SetStateAction<App.CompanyInformation[]>>
  startDate: string
  setStartDate: React.Dispatch<React.SetStateAction<string>>
  endDate: string
  setEndDate: React.Dispatch<React.SetStateAction<string>>
  detailedAssignment: App.StudentAssignment | undefined
  setDetailedAssignment: React.Dispatch<React.SetStateAction<App.StudentAssignment | undefined>>
  assignmentsReportsData: App.AssignmentTableType[]
  setAssignmentsReportsData: React.Dispatch<React.SetStateAction<App.AssignmentTableType[]>>
  assignmentsData: App.Assignment[] | undefined
  setAssignmentsData: React.Dispatch<React.SetStateAction<App.Assignment[] | undefined>>
  allStudentAssignments: App.AssignmentShort[] | undefined
  setAllStudentAssignments: React.Dispatch<React.SetStateAction<App.AssignmentShort[] | undefined>>
  companyData: App.CompanyInformation | undefined
  setCompanyData: React.Dispatch<React.SetStateAction<App.CompanyInformation | undefined>>
  isLessonCompleted: boolean
  setIsLessonCompleted: React.Dispatch<React.SetStateAction<boolean>>
  isModalOpen: boolean
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  filtersData: App.AssignmentTableType[]
  setFiltersData: React.Dispatch<React.SetStateAction<App.AssignmentTableType[]>>
  clearContextData: () => void
}>({
  companiesData: [],
  setCompaniesData: () => {},
  startDate: moment().subtract(1, "year").format("YYYY-MM-DD"),
  setStartDate: () => {},
  endDate: moment().add(1, "year").format("YYYY-MM-DD"),
  setEndDate: () => {},
  detailedAssignment: undefined,
  setDetailedAssignment: () => {},
  assignmentsReportsData: [],
  setAssignmentsReportsData: () => {},
  assignmentsData: [],
  setAssignmentsData: () => {},
  allStudentAssignments: undefined,
  setAllStudentAssignments: () => {},
  selectedAssignment: null,
  setSelectedAssignment: () => {},
  companyData: undefined,
  setCompanyData: () => {},
  isLessonCompleted: false,
  setIsLessonCompleted: () => {},
  isModalOpen: false,
  setIsModalOpen: () => {},
  filtersData: [],
  setFiltersData: () => {},
  clearContextData: () => {},
})

export const SecondaryContextProvider: React.FC<SecondaryContextProps> = ({ children }) => {
  const [allStudentAssignments, setAllStudentAssignments] = useState<App.AssignmentShort[] | undefined>(undefined)
  const [assignmentsData, setAssignmentsData] = useState<App.Assignment[] | undefined>(undefined)
  const [assignmentsReportsData, setAssignmentsReportsData] = useState<App.AssignmentTableType[]>([])
  const [detailedAssignment, setDetailedAssignment] = useState<App.StudentAssignment | undefined>(undefined)
  const [startDate, setStartDate] = useState<string>(moment().subtract(3, "months").format("YYYY-MM-DD"))
  const [endDate, setEndDate] = useState<string>(moment().add(1, "year").format("YYYY-MM-DD"))
  const [companiesData, setCompaniesData] = useState<App.DataCompanyType[]>([])
  const [selectedAssignment, setSelectedAssignment] = useState<App.Assignment | null>(null)
  const [companyData, setCompanyData] = useState<App.CompanyInformation>()
  const [isLessonCompleted, setIsLessonCompleted] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [filtersData, setFiltersData] = useState<App.DataAssignmentType[]>([])

  const clearContextData = () => {
    setDetailedAssignment(undefined)
  }

  const contextValue = {
    clearContextData,
    isModalOpen,
    setIsModalOpen,
    companyData,
    setCompanyData,
    selectedAssignment,
    setSelectedAssignment,
    companiesData,
    setCompaniesData,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    detailedAssignment,
    setDetailedAssignment,
    assignmentsReportsData,
    setAssignmentsReportsData,
    assignmentsData,
    setAssignmentsData,
    allStudentAssignments,
    setAllStudentAssignments,
    isLessonCompleted,
    setIsLessonCompleted,
    filtersData,
    setFiltersData,
  }

  return <SecondaryContext.Provider value={contextValue}>{children}</SecondaryContext.Provider>
}
