import { notification } from "antd"
import { SvgIcon } from "../handlers/icon"

export const BASE_URL = "https://avstar-api-dev.azurewebsites.net/api"

let isNotificationOpen = false

export const openErrorNotification = () => {
  if (!isNotificationOpen) {
    isNotificationOpen = true
    notification.open({
      placement: "bottomRight",
      message: "Error",
      description: "Something went wrong, please try again later",
      icon: <SvgIcon type={"crossIcon"} fill={"#B25758"} />,
      className: "notification-error",
      duration: 4,
      onClose: () => {
        isNotificationOpen = false
      },
    })
  }
}
