import { Avatar, Button, Dropdown, Row, Space, Tooltip } from "antd"
import React, { FC, useContext, useMemo } from "react"
import { useNavigate } from "react-router"
import { NavLink } from "react-router-dom"
import { useLocation } from "react-use"
import { SecondaryContext } from "../../context/SecondaryContext"
import { SvgIcon } from "../../handlers/icon"
import WebsiteMenu from "../../handlers/menu"
import { useApp } from "../app"
import { getUserFromSessionStorage, setUserToSessionStorage } from "../forms/sign-in"
import { Logo } from "../ui/logo"
import { BurgerMenu } from "../ui/menu/BurgerMenu"
import "./Header.less"
const Header: FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const user = getUserFromSessionStorage()
  const { clearContextData } = useContext(SecondaryContext)
  const handleSignOut = () => {
    document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"
    clearContextData()
    user && setUserToSessionStorage(null)
    navigate("/sign-in")
  }

  const getUserInitials = useMemo(() => {
    if (user) {
      const { firstName, lastName } = user
      return `${firstName[0]}${lastName[0]}`
    }
    return ""
  }, [user])

  const handleGroupAdminOption = useMemo(() => {
    if (location.pathname === "/dashboard") {
      return {
        key: "0",
        label: (
          <NavLink className={"dropdown-btn"} to={`/students`}>
            <SvgIcon type={"homeIcon"} fill={"#1E1E1E"} />
            Switch to Manager
          </NavLink>
        ),
      }
    } else {
      return {
        key: "0",
        label: (
          <NavLink className={"dropdown-btn"} to={`/dashboard`}>
            <SvgIcon type={"studentHatIcon"} fill={"#1E1E1E"} />
            Switch to Student
          </NavLink>
        ),
      }
    }
  }, [user, location.pathname])

  const items = [
    user?.type === 1 ? handleGroupAdminOption : null,
    {
      key: "1",
      label: (
        <NavLink to={`/profile/${btoa(`${user?.userID}`)}`} className={"dropdown-btn"}>
          <SvgIcon type={"eyeIcon"} fill={"#1E1E1E"} />
          View Profile
        </NavLink>
      ),
    },
    {
      key: "2",
      label: (
        <Button onClick={handleSignOut} className={"transparent dropdown-btn"}>
          <SvgIcon type={"signOut"} fill={"#B25758"} />
          <span className={"text-danger"}>Log Out</span>
        </Button>
      ),
    },
  ]

  const { nav } = useApp()
  return (
    <Row className={"container-header"} wrap={false} align={"middle"} justify={"center"}>
      {nav.visible && <WebsiteMenu mode={"horizontal"} />}
      {nav.visible && <BurgerMenu />}
      <Logo />
      {user && user.type !== 99 ? (
        <Space direction={"horizontal"}>
          <Tooltip overlayStyle={{ paddingTop: 8 }} title={"Click for Support"} color={"#009FDD"}>
            <Button
              href={"https://www.avstar.com/contact"}
              shape={"circle"}
              target={"_blank"}
              className={"support-btn"}
              icon={<SvgIcon type={"supportIcon"} className={"support-icon"} />}
            />
          </Tooltip>
          <Dropdown.Button
            className={"user-avatar"}
            icon={<Avatar size={40}>{getUserInitials}</Avatar>}
            trigger={["click"]}
            menu={{ items }}
            placement={"bottomLeft"}
          />
        </Space>
      ) : (
        <Button type={"ghost"} onClick={handleSignOut} className={"single"}>
          <SvgIcon type={"signOut"} fill={"#FFFFFF"} />
          <span>Log Out</span>
        </Button>
      )}
    </Row>
  )
}

export { Header }
