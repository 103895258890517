import { Drawer } from "antd"
import React, { FC } from "react"
import { SvgIcon } from "../../../handlers/icon"
import WebsiteMenu from "../../../handlers/menu"
import { ContextProvider, useApp } from "../../app"

const BurgerMenu: FC = () => {
  const { burger } = useApp()
  return (
    <ContextProvider>
      <Drawer className={"mobile-menu"} width={"100%"} height={"100%"} onClose={burger.toggle} open={burger.opened}>
        <WebsiteMenu onClick={burger.toggle} className={"mobile-menu"} mode={"vertical"} />
      </Drawer>
      <SvgIcon className={"burger-icon"} type={"burgerIcon"} onClick={() => burger.toggle()} />
    </ContextProvider>
  )
}

export { BurgerMenu }
