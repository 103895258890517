import axios, { AxiosError } from "axios"

import { App } from "../types"
import { BASE_URL, openErrorNotification } from "./index"

const login = async (values: { username: string; password: string }): Promise<App.UserLoginResponse | false> => {
  const requestBody = {
    username: values.username,
    password: values.password,
  }

  try {
    const response = await axios.post(`${BASE_URL}/login`, requestBody)
    return response.data
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response && error.response.status === 404) {
        return false
      } else {
        openErrorNotification()
      }
    }
    throw error
  }
}

const updatePassword = async (requestBody: App.UpdatePasswordForm): Promise<boolean> => {
  try {
    await axios.put(`${BASE_URL}/users/update-password`, requestBody)
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError
      if (axiosError.response) {
        console.error(axiosError)
        return false
      }
    }
  }
  return true
}
const resetPasswordSendEmail = async (username: string): Promise<boolean> => {
  try {
    const response = await axios.post(`${BASE_URL}/login/forgot-password/${username}`)
    return response.status === 200
  } catch (error) {
    if (error) {
      openErrorNotification()
    }
    throw error
  }
}

const checkUsername = async (username: string): Promise<boolean> => {
  try {
    const response = await axios.get(`${BASE_URL}/users/username/${username}`)
    return response.status === 200
  } catch (error) {
    if (error) {
      openErrorNotification()
    }
    return false
  }
}

const validateUsername = async (username: string): Promise<boolean> => {
  try {
    const response = await axios.post(`${BASE_URL}/login/forgot-password/username-valid/${username}`)
    return response.status === 200
  } catch (error) {
    return false
  }
}
const validateToken = async (token: string): Promise<number> => {
  const response = await axios.post(`${BASE_URL}/login/forgot-password/token/${token}`)

  if (response.status === 200 && response.data) {
    return response.data
  } else {
    return 0
  }
}

export { checkUsername, login, resetPasswordSendEmail, updatePassword, validateToken, validateUsername }
